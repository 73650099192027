import PortoSeguro from '../../assets/images/imagesProdutoPorto/seguro_auto.png'
import AzulSeguro from '../../assets/images/imagesProdutoPorto/azul_seguro_assinaturas.png'
import Residencial from '../../assets/images/imagesProdutoPorto/seguro_residencial.png'
import Financiamento from '../../assets/images/imagesProdutoPorto/financiamento_auto.png'
import Vida from '../../assets/images/imagesProdutoPorto/seguro_vida.png'
import PortoFaz from '../../assets/images/imagesProdutoPorto/porto_faz.png'
import Instituto from '../../assets/images/imagesProdutoPorto/responsabilidade_civil_profissional.png'
import Game from '../../assets/images/cardCategoria/ps5.PNG'
import TVs from '../../assets/images/cardCategoria/TV.PNG'
import Geladeira from '../../assets/images/cardCategoria/geladeira.PNG'
import Eletronico from '../../assets/images/cardCategoria/edot.PNG'

import Azul from 'assets/images/PortoViagem/azul-viagens-logo.png'
import Latam from 'assets/images/PortoViagem/material/logo_163x63_transparente.png'
import TapCima from 'assets/images/ImagensTap/tap.cima.png'
import TapBaixo from 'assets/images/ImagensTap/tap.baixo.png'
import Smile from 'assets/images/PortoViagem/smiles.png'
import BeFly from 'assets/images/PortoViagem/BeFly.png'

import BannerSmile from 'assets/images/PortoViagem/banner-smiles.png'
// import BannerSmile from 'assets/images/PortoViagem/Banner_Site_Gol_620X340.jpg'
import BannerAzul from 'assets/images/PortoViagem/Banner_Latam.png'
import BannerLatam from 'assets/images/PortoViagem/material/foto_746x720.png'
import BannerTAP from 'assets/images/PortoViagem/Banner_TAP.png'
import ImgPorto from 'assets/images/LogosNovas/portoPlusLogo.png'

export const ServicoPortocarousel = [
  {
    img: PortoSeguro,
    txtTitle: 'Seguro Auto',
    color: '#01A1FD',
    login: false,
    txtInfo:
      'No App você troca 5.000 pontos por R$115 na fatura do cartão Porto',
  },
  {
    img: AzulSeguro,
    txtTitle: 'Seguro Azul',
    color: '#01A1FD',
    login: false,
    txtInfo:
      'No App você troca 5.000 pontos por R$115 na fatura do cartão Porto',
  },
  {
    img: Residencial,
    txtTitle: 'Seguro Residência',
    color: '#01A1FD',
    login: false,
    txtInfo:
      'No App você troca 5.000 pontos por R$115 na fatura do cartão Porto',
  },
  {
    img: Financiamento,
    txtTitle: 'Financiamento',
    color: '#01A1FD',
    login: false,
    txtInfo:
      'No App você troca 6.500 pontos por R$150 na fatura do cartão Porto',
  },
  {
    img: Vida,
    txtTitle: 'Vida',
    color: '#01A1FD',
    login: false,
    txtInfo:
      'No App você troca 6.500 pontos por R$150 na fatura do cartão Porto',
  },
  {
    img: PortoFaz,
    txtTitle: 'Porto Serviços',
    color: '#01A1FD',
    login: false,
    txtInfo:
      'Na nossa central você troca 6.500 pontos por R$150 na fatura do cartão Porto',
  },
  {
    img: Instituto,
    txtTitle: 'Instituto Porto',
    color: '#01A1FD',
    url: '',
    login: false,
    txtInfo:
      'Acesse "Trocar Pontos" e conheça as faixas de pontos que ajudam o Instituto Porto.',
  },
]

export const TroquePontos = [
  {
    img: Game,
    txtInfo: 'Game',
    color: '#BFCCEC',
    url: `category/16/1`,
    login: true,
  },
  {
    img: Geladeira,
    txtInfo: 'Casa e Cozinha',
    color: '#65CAFA',
    url: `category/5/1`,
    login: true,
  },
  {
    img: Eletronico,
    txtInfo: 'Eletrônicos',
    color: '#FCA2FA',
    url: `category/6/1`,
    login: true,
  },
  {
    img: TVs,
    txtInfo: 'TVs',
    color: '#8181FE',
    url: `search/1/filter/JTdCJTIydGVybSUyMiUzQSUyMnR2JTIyJTdE`,
    login: true,
  },
]

export const OfertaViagem = [
  {
    name: 'BeFly',
    img: BeFly,
    // txtInfo: 'Tam',
    color: '#FFF',
    url: `/detalhe-Viagem`,
    btn: false,
    banne: BannerLatam,
    title: 'Sobre o programa BeFly',
    descr:
      '<p>Programa de fidelidade com milhares de opções para juntar pontos e trocar por voos. São 142 destinos em 25 países, além de estar presente em seis mercados domésticos da América Latina (Argentina, Brasil, Chile, Colômbia, Equador e Peru) e mantém operações internacionais na região e para Europa, Estados Unidos, Caribe, Oceania, África e Ásia.</p><br><br><p><strong>Importante saber:</strong></p><ul><li>Certifique-se de que possui uma conta no programa parceiro e os dados estão completos e atualizados;</li><li>Para resgates, você pode ligar na Central de Atendimento 24h;</li><li>Só serão aceitas transferências para a mesma titularidade;</li><li>A quantidade mínima de transferência para o programa LATAM Pass é de 20.000 pontos;</li><li>1 ponto Portoplus = 1 ponto Latam </li></ul>',
    login: true,
  },
  {
    name: 'Smile',
    img: [Smile],
    // txtInfo: 'Smiles',
    color: '#FFF',
    url: `/detalhe-Viagem`,
    btn: false,
    banne: BannerSmile,
    title: 'Sobre o programa Smiles',
    descr:
      '<p style="text-align: justify">A Smiles é o Programa de Fidelidade da GOL e uma Plataforma completa de Viagens, com mais de 58 companhias aéreas parceiras que viajam para mais de 160 países e 1.600 destinos para você viver o mundo. Na Smiles, seus pontos viram milhas, que podem ser trocadas por reservas em mais de 700 mil opções de hotéis, aluguel de carros, entrada de shows, parques, atrações, passeios, comprar créditos Uber e ainda em mais de mil produtos dos parceiros do Shopping Smiles.   </p>    <p>As milhas são recompensas que você recebe ao voar, transferir pontos do cartão, fazer compras ou utilizar outros serviços da Smiles. Quanto mais você acumula, mais longe você pode ir nas suas viagens.    </p><br><p><strong>Importante saber:</strong></p>    <ul style="text-align: justify"><li>Certifique-se de que possui uma conta no programa parceiro e os dados estão completos e atualizados;</li>    <li>Para resgates, você pode ligar na Central de Atendimento 24h;</li>    <li>Só serão aceitas transferências para a mesma titularidade;</li>    <li>A quantidade mínima de transferência para o programa Smiles é de 20.000 pontos;</li><li>1 ponto Portoplus = 1 ponto Smiles.</li></ul>',
  },
  {
    name: 'Azul',
    img: [Azul],
    // txtInfo: 'Azul',
    color: '#FFF',
    url: `/detalhe-Viagem`,
    btn: false,
    banne: BannerAzul,
    title: 'Sobre o programa TudoAzul',
    descr:
      '<p style="text-align: justify">O Programa TudoAzul da Azul Linhas Aéreas oferece a você uma experiência diferenciada em suas viagens: a única companhia da América Latina com TV ao vivo nos jatos, a frota mais moderna e confortável operando em todos os Estados do Brasil  além de Fort Lauderdale, Orlando e Lisboa sem escalas. São mais de 130 opções de destinos em quase 900 voos diários.</p><br><p><strong>Importante saber:</strong></p><ul style="text-align: justify"><li>Certifique-se de que possui uma conta no programa parceiro e os dados estão completos e atualizados;</li><li>Para resgates, você pode ligar na Central de Atendimento 24h;</li><li>Só serão aceitas transferências para a mesma titularidade;</li><li>A quantidade mínima de transferência para o programa TudoAzul é de 20.000 pontos;</li><li>1 ponto Portoplus = 1 ponto TudoAzul.</li></ul>',
  },
  {
    name: 'Tap',
    img: [TapCima, TapBaixo],
    // txtInfo: 'Tam',
    color: '#FFF',
    url: `/detalhe-Viagem`,
    btn: false,
    banne: BannerTAP,
    title: 'Sobre o programa TAP Miles&Go',
    descr:
      '<p style="text-align: justify">A TAP é a companhia que mais voa entre o Brasil e a Europa, operando a partir de 10 cidades brasileiras: São Paulo, Rio de Janeiro, Porto Alegre, Belo Horizonte, Brasília, Recife, Salvador, Fortaleza, Natal e Belém. Além disso, você poderá usar suas milhas para voar em mais 30 companhias aéreas da Star Alliance ou outras companhias parceiras que juntas operam para mais de 1.300 destinos em todo o mundo.</p><br><p><strong>Importante saber:</strong></p><ul style="text-align: justify"><li>Certifique-se de que possui uma conta no programa parceiro e os dados estão completos e atualizados;</li><li>Para resgates, você pode ligar na Central de Atendimento 24h;</li><li>Só serão aceitas transferências para a mesma titularidade;</li><li>A quantidade mínima de transferência para o programa TAPMiles&Go é de 20.000 pontos;</li><li>1 ponto Portoplus = 1 ponto TAP Miles&Go. </li></ul>',
  },
  {
    name: 'Latam',
    img: [Latam],
    // txtInfo: 'Tam',
    color: '#FFF',
    url: `/detalhe-Viagem`,
    btn: false,
    banne: BannerLatam,
    title: 'Sobre o programa LATAM Pass',
    descr:
      '<p style="text-align: justify">Programa de fidelidade com milhares de opções para juntar pontos e trocar por voos. São 142 destinos em 25 países, além de estar presente em seis mercados domésticos da América Latina (Argentina, Brasil, Chile, Colômbia, Equador e Peru) e mantém operações internacionais na região e para Europa, Estados Unidos, Caribe, Oceania, África e Ásia.</p><br><p><strong>Importante saber:</strong></p><ul style="text-align: justify"><li>Certifique-se de que possui uma conta no programa parceiro e os dados estão completos e atualizados;</li><li>Para resgates, você pode ligar na Central de Atendimento 24h;</li><li>Só serão aceitas transferências para a mesma titularidade;</li><li>A quantidade mínima de transferência para o programa LATAM Pass é de 20.000 pontos;</li><li>1 ponto Portoplus = 1 ponto Latam. </li></ul>',
  },
]
