import verifyIsLoginActive from 'util/verifyIsLoginActive'
import axios from 'shared/http'

const openExternalLink = (url) => {
  const handleSso = (ssoUrl) => {
    if (!ssoUrl) {
      console.error('SSO URL é inválida ou não foi fornecida.')
      return
    }
    window.open(ssoUrl, '_blank')
  }

  const getUrlAwsMkt = async () => {
    const data = { path: url }
    const urlEndpoint = '/participants/v1/session-marketplace'

    try {
      const response = await axios.post(urlEndpoint, data)

      if (response?.ssoUrl) {
        handleSso(response.ssoUrl)
      } else {
        console.error('SSO URL não encontrada na resposta.')
      }
    } catch (error) {
      console.error('Erro ao obter a URL do SSO:', error?.message || error)
    }
  }

  // Verifica se o login do usuário está ativo antes de prosseguir
  const isLoginActive = verifyIsLoginActive()

  if (isLoginActive) {
    getUrlAwsMkt()
  } else {
    console.warn('Usuário não está logado. Operação abortada.')
  }
}

export default openExternalLink
