import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import openExternalLink from 'util/LinkToShowCase'
import axios from 'shared/http'

const LinkToShowCase = (props) => {
  const [state] = React.useState({
    isLoggedIn: localStorage.getItem('auth-token'),
    linkCatalogo: '',
  })

  function verTodos(e) {
    e.preventDefault()
    openExternalLink(state.linkCatalogo)
  }

  return <Link onClick={verTodos}>{props.children}</Link>
}

export default LinkToShowCase
