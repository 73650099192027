import React from 'react'
import Game from '../../../assets/images/cardCategoria/imagens com fundos/Imagem Main Content.png'
import Lazer from '../../../assets/images/cardCategoria/imagens com fundos/Imagem Main Content-1.png'
import Smart from '../../../assets/images/cardCategoria/imagens com fundos/Imagem Main Content-2.png'
import Casa from '../../../assets/images/cardCategoria/imagens com fundos/Imagem Main Content-3.png'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import LoginBox from 'containers/LoginBox/LoginBox'
import axios from 'shared/http'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { SearchIcon } from 'assets/Icons/NovosIcons'

import { useHistory } from 'react-router-dom'

import { Styled } from './Categoria.styled'
import { Card } from 'react-bootstrap'
import openExternalLink from 'util/LinkToShowCase'

const VitrineCategoria = ({ showModal }) => {
  const history = useHistory()
  const list = [
    {
      txtInfo: 'Game',
      img: Game,
      url: `category/16/1`,
      desc: 'Os melhores Video Game com os melhores cashback você encontra aqui',
    },
    {
      txtInfo: 'Smartphones',
      img: Smart,
      url: `search/1/filter/JTdCJTIydGVybSUyMiUzQSUyMmNlbHVsYXIlMjIlN0Q=`,
      desc: 'Os melhores Smartphones com os melhores cashback você encontra aqui',
    },
    {
      txtInfo: 'TV e vídeo',
      img: Lazer,
      url: `search/1/filter/JTdCJTIydGVybSUyMiUzQSUyMnR2JTIyJTdE`,
      desc: 'As melhores TV e vídeo com os melhores cashback você encontra aqui',
    },
    {
      txtInfo: 'Casa e cozinha',
      img: Casa,
      url: `category/5/1`,
      desc: 'Os melhores Eletrodomésticos com os melhores cashback você encontra aqui',
    },
  ]

  const getUrl = (url) => {
    openExternalLink(url)
  }

  const authToken = localStorage.getItem('auth-token') || null
  const handleIsLogin = (url, login, id) => {
    if (url && !login) {
      return window.open(url, '_blank')
    }

    if (url != undefined && login) {
      if (authToken != null) {
        getUrl(url)
      } else {
        history.push('/Login', { id: id, url: url })
      }
    } else {
      history.push('/Login')
    }
  }
  return (
    <Container>
      <Row>
        <Col>
          <Styled.Content>
            <Styled.txtH2>Se preferir, navegue por categorias.</Styled.txtH2>
            <Row>
              {list.map((itens, i) => {
                return (
                  <Col className="mb-4" xs={12} md={6}>
                    <Card
                      onClick={() => handleIsLogin(itens.url, true)}
                      className="h-100 poiter"
                    >
                      <Row>
                        <Col className="card-hegth d-flex justify-content-center align-content-center align-items-center">
                          <Col xs={6} md={6}>
                            <Col>
                              <Card.Img className="p-4" src={itens.img} />
                            </Col>
                          </Col>
                          <Col className="p-1" xs={6} md={6}>
                            <h1 className="h4">{itens.txtInfo}</h1>
                            <p className="txt p-3">{itens.desc}</p>
                            <h1 className="mt-3 txt-link">
                              Acessar <SearchIcon />
                            </h1>
                          </Col>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </Styled.Content>
        </Col>
      </Row>
    </Container>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(VitrineCategoria)
